import { useEffect } from "react";

const useColorChangeOnScroll = (sectionRef, textRef) => {

  const handleColorChange = () => {
    const { current: sectionElement } = sectionRef;
    const { current: textElement } = textRef;
    
    if (!sectionElement || !textElement) return;

    const sectionTop = sectionElement.getBoundingClientRect().top;
    const windowHeight = window.innerHeight;
    const effectStartThreshold = windowHeight * 0.3;

    if (sectionTop > effectStartThreshold) {
      textElement.style.color = "rgba(128, 128, 128, 1)";
      textElement.style.backgroundImage = "none";
      return;
    }

    const progress = (effectStartThreshold - sectionTop) / windowHeight;
    const gradientOffset = Math.min(progress * 300, 120);

    const gradient = `linear-gradient(to bottom right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) ${gradientOffset - 15}%, rgba(255, 255, 255, 0.7) ${gradientOffset}%, rgba(128, 128, 128, 0.7) ${gradientOffset + 15}%, rgba(128, 128, 128, 1) 100%)`;

    textElement.style.backgroundImage = gradient;
    textElement.style.WebkitBackgroundClip = "text";
    textElement.style.color = "transparent";
  };

  useEffect(() => {
    window.addEventListener("scroll", handleColorChange);

    return () => {
      window.removeEventListener("scroll", handleColorChange);
    };
  }, []);

};

export default useColorChangeOnScroll;
