import React, { useRef } from "react";
import styles from "./AllSection2.module.css";
import useScrollZoom from "../../../components/useScrollZoom";
import useColorChangeOnScroll from "../../../components/useColorChangeOnScroll";

const AllSection2 = () => {
  const textRef = useRef(null);
  const sectionRef = useScrollZoom();

  useColorChangeOnScroll(sectionRef, textRef);

  return (
    <div ref={sectionRef} className={`${styles.section} section`}>
      <div className={`${styles.overlay} overlay`}>
        <div className={`${styles.text}`}>
          <h3 ref={textRef} className={`${styles.textBody} textBody`}>
            Imagine: The ornate doors swing open, revealing high-end flats in
            Khajaguda, a world beyond imagination. Upon arrival, excitement
            washes over you, as if enveloped in magic. The lobby's allure
            captivates your senses, from the shimmering chandeliers to the
            velvety carpets that beckon you to explore. At Quambiant
            Amaranthine, suite-styled 3/4/5 BHK flats near Khajaguda in
            Hyderabad, discover a place where luxury knows no limits and immerse
            yourself in a lifestyle that matches your expectations. Welcome to a
            realm where the enchantment of suite-style living awaits at every
            turn.
          </h3>
        </div>
      </div>
    </div>
  );
};

export default AllSection2;
