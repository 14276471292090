import React, { useRef } from "react";
import styles from "./Section2.module.css";
import useScrollZoom from "../../../components/useScrollZoom";
import useColorChangeOnScroll from "../../../components/useColorChangeOnScroll";

const Section2 = () => {
  const sectionRef = useScrollZoom();
  const textRef = useRef(null);

  useColorChangeOnScroll(sectionRef, textRef);

  return (
    <div ref={sectionRef} className={`${styles.section} section`}>
      <div className={`${styles.overlay} overlay`}>
        <div className={`${styles.text}`}>
          <h3 ref={textRef} className={`${styles.textBody} textBody`}>
            Relish your leisure time with amenities, or stay productive at the
            business centre. Form a deeper bond with your loved ones through a
            multitude of activities.
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Section2;
