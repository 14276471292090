import React from "react";
import styles from "./site-visit-thankyou.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';


function Thankyou() {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1); 
        
    };
    

  return (
    <div className={styles.thankyouRoot + "  "}>
      <div className={styles.backContainer}  onClick={handleBack}>
  
        <FontAwesomeIcon icon={faAngleLeft} className={styles.backAngleIcon} /> 
        <span >Back</span>
      </div>
      <h1>Thankyou!</h1>
      <p>
        Your request has been submitted. Our team will get back to you soon.
      </p>
    </div>
  );
}

export default Thankyou;
