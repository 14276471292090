import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import logo from "../assets/images/bacgrounds/logo.webp";

const metaInfo = {
  "/": {
    title: "Luxury Apartments in Khajaguda, Hyderabad | Amaranthine Living",
    description:
      "3, 4 & 5 BHK ultra luxury apartments in Khajaguda, Hyderabad by a reputed builder. Book a site visit.",
    image: logo,
  },
  "/ClubHouse": {
    title: "Clubhouse with 50+ Amenities | Amaranthine Living",
    description:
      "Explore the wide range of amenities at our exclusive 38,000 Sq ft Clubhouse. Get in touch.",
    image: logo,
  },
  "/ProjectDetails": {
    title: "Project Details | Amaranthine Living",
    description:
      "Learn more details of Amaranthine Living here. Book a site visit.",
    image: logo,
  },
  "/Plans": {
    title: "Explore Floor Plans & Master Plan | Amaranthine Living",
    description:
      "Detailed floor plans and master plan of Amaranthine Living. Request a callback.",
    image: logo,
  },
  "/AboutUs": {
    title: "About Us | Amaranthine Living",
    description: "Learn more about the Builders here. Request a callback.",
    image: logo,
  },
  "/ContactUs": {
    title: "Contact Us | Amaranthine Living",
    description: "Contact Us | Amaranthine Living",
    image: logo,
  },
  "/thankyou": {
    title: "Thankyou | Amaranthine Living",
    description: "Thankyou | Amaranthine Living",
    image: logo,
  },
  "/sitevisit-thankyou": {
    title: "Thankyou | Amaranthine Living",
    description: "Thankyou | Amaranthine Living",
    image: logo,
  },
  "/Flats-in-Banjara-Hills": {
    title: " Luxury Apartments in Banjara Hills",
    description: "3, 4 & 5 BHK  High End apartments in Banjara hills, Hyderabad by a reputed builder. Book a site visit.",
    image: logo,
  },
  "/Flats-in-Gachibowli": {
    title: " Luxury Apartments in Gachibowli",
    description: "3, 4 & 5 BHK  High End apartments in Gachibowli, Hyderabad by a reputed builder. Book a site visit.",
    image: logo,
  },
  "/Flats-in-Jubilee-Hills": {
    title: " Luxury Apartments in Jubilee Hills",
    description: "3, 4 & 5 BHK  High End apartments in Jubilee hills, Hyderabad by a reputed builder. Book a site visit.",
    image: logo,
  },
};

const DynamicMetaTags = () => {
  const location = useLocation();
  const currentMeta = metaInfo[location.pathname] || metaInfo["/"];
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "ApartmentComplex",
    "name": "Amaranthine Living",
    "url": "https://quambiantamaranthine.com/",
    "image": "https://quambiantamaranthine.com/favicon.png",  // Replace with actual image URL
    "description": "Luxury apartments in Khajaguda, Hyderabad offering 3, 4, & 5 BHK options.",
    "telephone": "+91 9774123456",  // Replace with actual phone number
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "123 Amaranthine Blvd",
      "addressLocality": "Hyderabad",
      "addressRegion": "TS",
      "postalCode": "500084",
      "addressCountry": "IN"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": "17.4140", 
      "longitude": "78.4086"
    }
  };
  

  return (
<Helmet>
  <script type="application/ld+json">
    {JSON.stringify(structuredData, null, 2)}
  </script>
  <title>{currentMeta.title}</title>
  <meta name="description" content={currentMeta.description} />
  <meta property="og:title" content={currentMeta.title} />
  <meta property="og:description" content={currentMeta.description} />
  <meta property="og:image" content={currentMeta.image} />
  <meta property="og:url" content={`https://quambiantamaranthine.com${location.pathname}`} />
  <link rel="canonical" href={`https://quambiantamaranthine.com${location.pathname}`} />
</Helmet>

  );
};

export default DynamicMetaTags;
