import React, { useState } from "react";
import styles from "./Section16.module.css";
import dropDownArrow from "../../../assets/images/dropDownArrow.svg"
import dropDownUpArrow from "../../../assets/images/dropDownUpArrow.svg"


const items = [
  {
    title: 'Schools',
    values: [
      { name: 'Delhi Public School', time: '3 mins' },
      { name: 'Oakridge International School', time: '8 mins' },
    ],
  },
  {
    title: 'Hospitals',
    values: [
      { name: 'KIMS Hospital', time: '4 mins' },
      { name: 'Care Hospital', time: '8 mins' },
    ],
  },
  {
    title: 'Super Market',
    values: [
      { name: 'Ratnadeep Super Market', time: '6 mins' },
      { name: 'D-Mart', time: '7 mins' },
    ],
  },
  {
    title: 'Connectivity',
    values: [
      { name: 'Lanco Hills', time: '2 mins' },
      { name: 'Raidurg Metro Station', time: '13 mins' },
    ],
  },
  {
    title: 'Work Places',
    values: [
      { name: 'Hitech City ', time: '13 mins' },
      { name: 'Financial District', time: '13 mins' },

    ],
  },
  // ...other categories with varying numbers of values
];


const Section16 = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={styles.section}>
      <div className={styles.overlay}>
        <div className={styles.mobile}>
          {items.map((item, index) => (
            <div key={index} className={`${activeIndex === index ? "expanded" : ""} ${styles.dropDownContainer}`}>
              <button
                className="btn btn-link text-white d-flex align-items-center justify-content-between"
                type="button"
                onClick={() => handleToggle(index)}
              >
                {item.title}
                {activeIndex === index ? (
                  <img src={dropDownUpArrow} className={styles.dropDownUpArrow} alt="up arrow" />
                ) : (
                  <img src={dropDownArrow} className={styles.dropDownArrow} alt="down arrow" />
                )}
              </button>
              {activeIndex === index && (
                <div className={styles.dropDownDetails}>
                  {item.values.map((value, valueIndex) => (
                    <div key={valueIndex}>
                      <div className={styles.nameValue}>{value.name}</div>
                      <div className={styles.descValue}>{value.time}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section16;