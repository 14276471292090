import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import "./App.css";
import Header from "./components/Header/Header";
import Footer from './components/Footer/Footer';
import MainContent from './MainContent';
import DynamicMetaTags from './components/DynamicMetaTags';
import logo from "./assets/images/bacgrounds/logo.webp";
import BrochureButton from './components/BrochureButton/BrochureButton';
import DisclaimerPopup from './components/Disclaimer/Disclaimer';
import ContactBtn from './components/contactBtn/contactBtn';

const excludeHeader = ["/privacy-policy", "/terms-of-services", "/General", "/Legal", "/thankyou", "/sitevisit-thankyou"];
const excludeFooter = ["/privacy-policy", "/terms-of-services", "/General", "/Legal", "/droneview", "/sitevisit-thankyou"];

function ContentWrapper() {
  const location = useLocation();

  useEffect(() => {
    const addTrackingScript = () => {
      console.log("Adding tracking pixel script for ID: 17614");

      const script = document.createElement("script");
      script.setAttribute("data-pixel-id", "17614");
      script.text = `!function(px){
        function e(t,e,c){
          var n="",r="";
          try{
            ""!=(n=function(t,e){
              try{
                var c={};
                e.replace(/[?&]+([^=&]+)=([^&]*)/gi,function(t,e,n){
                  c[e]=n
                });
                return c.hasOwnProperty(t)?c[t]:""
              }catch(t){
                return""
              }
            }(t,c))?function(t,e,c){
              try{
                var n,r;
                c?((n=new Date).setTime(n.getTime()+864e5),r="; expires="+n.toGMTString()):r="";
                document.cookie=t+"="+e+r+";Path=/"
              }catch(t){}
            }(e,n,1):n=function(t){
              try{
                var e=document.cookie.match(new RegExp("(^| )"+t+"=([^;]+)"));
                if(e) return e[2]
              }catch(t){}
                return null
              }(e),r=""!=n&&null!=n?"&"+t+"="+n:"&"+t+"="
            }catch(t){}
            return r
          }
          var c="",n="",r="";
          try{
            n=e("ad","acf",c=window.location.href),
            r=e("col_ci","col_ci",c)
          }catch(t){
            console.log(t)
          }
          var a="https://ade.clmbtech.com/cde/eventTracking.htm?pixelId="+px+"&_w=1&_t=2"+n+r+"&rd="+(new Date).getTime();
          console.log('Tracking URL:', a); // Log the URL
          (new Image).src=a
        }('17614');`;

      document.body.appendChild(script);
      sessionStorage.setItem('trackingPixelAdded', 'true');
    };

    const handleInitialLoad = () => {
      if (!sessionStorage.getItem('trackingPixelAdded')) {
        console.log("Initial load detected, adding tracking pixel after delay");
        setTimeout(addTrackingScript, 2000); // 2 seconds delay for initial load
      } else {
        console.log("Reload detected, tracking pixel already added");
      }
    };

    if (location.pathname === '/') {
      handleInitialLoad();
    }

    return () => {
      console.log("Cleaning up tracking pixel script for ID: 17614");
      const scriptToRemove = document.querySelector(`script[data-pixel-id="17614"]`);
      if (scriptToRemove) {
        document.body.removeChild(scriptToRemove);
      }
      sessionStorage.removeItem('trackingPixelAdded');
    };
  }, [location.pathname]);

  return (
    <div className="App">
      <DynamicMetaTags />
      {!excludeHeader.includes(location.pathname) && <Header />}
      <MainContent />
      {!excludeFooter.includes(location.pathname) && <Footer />}
    </div>
  );
}

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  useEffect(() => {
    const hasAcceptedDisclaimer = localStorage.getItem('hasAcceptedDisclaimer');
    if (!hasAcceptedDisclaimer) {
      setShowDisclaimer(true);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          setTimeout(() => setIsLoading(false), 250);
          return 100;
        }
        return prevProgress + 5;
      });
    }, 30);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (isLoading) {
    return (
      <div className="progressBarContainer">
        <img src={logo} alt='logo' className={"homeLogo"}/>
        <p className={"AmaranthineLoader"}> Amaranthine </p>
        <div className="progressWrapper">
          <div className="progressBar" style={{ width: `${progress}%` }}></div>
        </div>
        <span className="progressBarText">{progress}%</span>
      </div>
    );
  }

  return (
    <>
      {showDisclaimer && <DisclaimerPopup onClose={() => {
        localStorage.setItem('hasAcceptedDisclaimer', 'true');
        setShowDisclaimer(false);
      }} />}
      <Router>
        <ContentWrapper />
        <ContactBtn />
      </Router>
    </>
  );
}

export default App;
