import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from "emailjs-com";
import styles from "./Footer.module.css";
import logo1 from "../../assets/images/bacgrounds/logo.webp";
import logo2 from "../../assets/images/quambiant_logo.png";
import fb from "../../assets/images/icons/facebook.svg";
import ig from "../../assets/images/icons/instagram.svg";
import yt from "../../assets/images/icons/youtube.svg";
import birla from "../../assets/images/NOC Sticker_page-0001.jpg"

const publicKey = "6-QdLjlzl5V_YnnGJ";
emailjs.init(publicKey);

const Footer = () => {
  const nearByLocation = [
    "Mehdipatnam",
    "Madhapur",
    "Nanakaramguda",
    "Narsingi",
    "Kokapet",
    "Financial District",
    "Mindspace",
    "Shaikpet",
    "Gachibowli",
    "Kondapur",
    "Banjara Hills",
    "Jubilee Hills",
    "Gandipet",
    "Raidurg",
    "Malakamcheruvu",
  ];
  const nearByProjects = [
    "SAS Diamond Towers",
    "Team4 Arka",
    "Lanco Hills",
    "Chitrapuri Colony",
    "My Home Bhooja Flats",
    "Aparna Sarovar Grande",
    "Rainbow Vistas Rock Garden",
    "Lodha Bellezza",
    "My Home Avatar",
    "SMR Vinay Iconia",
    "Prestige High Fields",
  ];

  return (
    // <div className={`${styles.section} section ${styles.section1}`}>
    //   <div className={`${styles.overlay} overlay`}>
    //     <footer className={`${styles.footer} text-white`}>
    //       <div className="">
    //         <div className="row">
    //           <div className={`${styles.footerLogo} col-4 col-md-4`}>
    //             <div>
    //               <img src={logo1} alt="Logo 1" className={`${styles.logo1}`} />
    //             </div>
    //             <div>
    //               <img src={logo2} alt="Logo 2" className={`${styles.logo2}`} />
    //             </div>
    //           </div>
    //           <div className={`${styles.sitemap} col-4 col-md-2`}>
    //             <h5>Sitemap</h5>
    //             <ul className={styles.Montserrat + "  "}>
    //               <li>
    //                 <a href="/">Home</a>
    //               </li>
    //               <li>
    //                 <a href="/ClubHouse">Club House</a>
    //               </li>
    //               <li>
    //                 <a href="/ProjectDetails">Project Details</a>
    //               </li>
    //               <li>
    //                 <a href="/Plans">Plans</a>
    //               </li>
    //               <li>
    //                 <a href="/Aboutus">About Us</a>
    //               </li>
    //               <li>
    //                 <a href="/ContactUs">Contact Us</a>
    //               </li>
    //             </ul>
    //           </div>
    //           <div className={`${styles.social} col-4 col-md-2`}>
    //             <h5>Socials</h5>
    //             <ul className={styles.Montserrat}>
    //               <li>
    //                 <a
    //                   href="https://www.facebook.com/profile.php?id=100092013073010"
    //                   target="_blank"
    //                   rel="noopener noreferrer"
    //                 >
    //                   Facebook
    //                 </a>
    //               </li>
    //               <li>
    //                 <a
    //                   href="https://www.instagram.com/amaranthine.living/"
    //                   target="_blank"
    //                   rel="noopener noreferrer"
    //                 >
    //                   Instagram
    //                 </a>
    //               </li>
    //               <li>
    //                 <a
    //                   href="https://www.youtube.com/channel/UCFwfOzD66xqzQdw_g_UMCWg"
    //                   target="_blank"
    //                   rel="noopener noreferrer"
    //                 >
    //                   YouTube
    //                 </a>
    //               </li>
    //             </ul>
    //           </div>
    //           <div className={`${styles.headoffice} col-md-4`}>
    //             <h5>Head Office</h5>
    //             <p className={styles.Montserrat}>
    //               Quambiant Amaranthine, Sy.No. 247, Malkam Cheruvu Rd,
    //               Chitrapuri Colony, Khajaguda Jagir, Telangana 500089
    //             </p>
    //             <h5>Book a Visit</h5>
    //             <form onSubmit={handleSubmit}>
    //               <input
    //                 type="text"
    //                 className={`${styles.inputText} form-control`}
    //                 placeholder="Full Name"
    //                 value={fullName}
    //                 onChange={(e) => setFullName(e.target.value)}
    //               />
    //               <input
    //                 type="text"
    //                 className={`${styles.inputText} form-control`}
    //                 placeholder="Mobile Number"
    //                 value={mobileNumber}
    //                 onChange={(e) => setMobileNumber(e.target.value)}
    //               />
    //               <button
    //                 type="submit"
    //                 className={`${styles.submitButton} btn btn-primary`}
    //               >
    //                   {isSubmitting ? 'Sending...' : 'Submit'}
    //               </button>
    //             </form>
    //           </div>
    //         </div>
    //         <div className={styles.nearByDiv}>
    //           <div className={styles.locationDiv}>
    //             <p className={styles.locationNearBy}>Nearby Locations</p>
    //             <ul className={styles.Montserrat + " " + styles.ulHead }>
    //               {nearByLocation.map((location, index) => (
    //                 <li key={index}>{location}</li>
    //               ))}
    //             </ul>
    //           </div>
    //           <div className={styles.locationDiv}>
    //             <p className={styles.projectNearBy}>Nearby Projects</p>
    //             <ul className={styles.Montserrat + " " + styles.ulHead }>
    //               {nearByProjects.map((location, index) => (
    //                 <li key={index}>{location}</li>
    //               ))}
    //             </ul>
    //           </div>
    //         </div>
    //         <div className={styles.infoRow + " row "}>
    //           <div className={styles.email + " col-md-4 "}>
    //             <p>Sales@quambiant.com </p>
    //           </div>
    //           <div className={styles.phone + " col-md-4 "}>
    //             <p>+91 9774 123456 &nbsp; &nbsp; </p>
    //             <p>+91 8876 123456</p>
    //           </div>
    //           <div className={styles.copyRight + " col-md-4 "}>
    //             <p>© 2022 Copyright Amaranthine. All rights reserved</p>
    //             <div className={styles.copyRight}>
    //               <a
    //                 href="https://credvest.com/"
    //                 target="_blank"
    //                 rel="noopener noreferrer"
    //                 className={styles.poweredBy}
    //               >
    //                 Powered by Credvest India Private Limited
    //               </a>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="row justify-content-center">
    //           {/* <div className="col-6 col-md-2 text-start">
    //             <a href="/Legal" className={styles.privacy}>
    //           LEGAL
    //             </a>
    //           </div>

    //           <div className="col-6  col-md-2 text-start">
    //             <a href="/General" className={styles.privacy}>
    //             General Info
    //             </a>
    //           </div> */}
    //           <div className="col-6 col-md-2 text-start">
    //             <a href="./privacy-policy" className={styles.privacy}>
    //               Privacy Policy
    //             </a>
    //           </div>
    //           <div className="col-6 col-md-2 text-start">
    //             <a href="./terms-of-services" className={styles.terms}>
    //               Terms of Service
    //             </a>
    //           </div>
    //         </div>
    //       </div>
    //     </footer>
    //   </div>
    //   {/* Thank you popup */}
    //   {showPopup && (
    //     <div className={styles.popupOverlay}>
    //       <div className={styles.popupContent}>
    //         <h3>Thank You!</h3>
    //         <p>
    //           Your request has been submitted. Our team will get back to you
    //           soon.
    //         </p>
    //         <button
    //           className={styles.popupButton}
    //           onClick={() => setShowPopup(false)}
    //         >
    //           Thank You
    //         </button>
    //       </div>
    //     </div>
    //   )}
    // </div>
    <div className={styles.nFooter}>
      <div className={styles.nAlignment}>
        <div className={styles.topRow}>
          {/* Image Logo */}
          <div className={styles.leftTop}>
            <img src={logo1} alt="Logo" className={`${styles.logo1}`} />
            <div className={styles.contactInfo}>
              <img src={birla} alt="Birla Icon" className={styles.birlaIcon}/>
              <p className={styles.email}>Sales@quambiant.com</p>
              <p className={styles.phone}>+91 9774123456, 8876123456</p>
            </div>
          </div>
          <div className={styles.rightTop}>
            <div className={styles.locationDiv}>
              <p className={styles.locationNearBy}>Nearby Locations</p>
              <ul className={styles.Montserrat + " " + styles.ulHead}>
                {nearByLocation.map((location, index) => (
                  <li key={index} className={styles.list}>
                    {location}
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.locationDiv}>
              <p className={styles.projectNearBy}>Nearby Projects</p>
              <ul className={styles.Montserrat + " " + styles.ulHead}>
                {nearByProjects.map((location, index) => (
                  <li key={index} className={styles.list}>
                    {location}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.btmRow}>
          <div className={styles.btmLeft}>
            <p className={styles.copyright}>
              © 2022 Copyright Amaranthine. All rights reserved
            </p>
            <p className={styles.credvest}>
              Powered by{" "}
              <a
                href="https://credvest.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Credvest India Private Limited
              </a>
            </p>
          </div>
          <div className={styles.btmCenter}>
            <p>Follow Us</p>
            <div className={styles.social}>
              <a
                href="https://www.facebook.com/profile.php?id=100092013073010"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={fb} alt="Logo" className={styles.icon} />
              </a>
              <a
                href="https://www.instagram.com/amaranthine.living/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={ig} alt="Logo" className={styles.icon} />
              </a>
              <a
                href="https://www.youtube.com/channel/UCFwfOzD66xqzQdw_g_UMCWg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={yt} alt="Logo" className={styles.icon} />
              </a>
            </div>
          </div>
          <div className={styles.btmRight}>
            <a href="/privacy-policy" className={styles.legalP}>
              Privacy Policy
            </a>
            <p className={styles.seperator}>|</p>
            <a href="/terms-of-service" className={styles.legalP}>
              Terms of Services
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
