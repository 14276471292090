import React, { useState } from "react";
import styles from "./Section5.module.css";
import DesktopGallary from "../../../components/DesktopGallary/DesktopGallary";
import MobileGallery from "../../../components/MobileGallary/MobileGallary";

const Section5 = () => {
  return (
    <div className={styles.section + " section "}>
      <div className={styles.desktop + ""}>
        <DesktopGallary />
      </div>
      <div className={styles.mobile + ""}>
        <MobileGallery />
      </div>
    </div>
  );
};

export default Section5;
