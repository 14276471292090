import React, { useState, useEffect, useRef } from "react";
import styles from "./DesktopGallary.module.css";

import gal1 from "../../assets/galleryimages/1.webp";
import gal2 from "../../assets/galleryimages/2.webp";
import gal3 from "../../assets/galleryimages/3.webp";
import gal4 from "../../assets/galleryimages/4.webp";
import gal5 from "../../assets/galleryimages/5.webp";
import gal6 from "../../assets/galleryimages/6.webp";
import gal7 from "../../assets/galleryimages/7.webp";
import gal8 from "../../assets/galleryimages/8.webp";
import gal9 from "../../assets/galleryimages/9.webp";
import gal10 from "../../assets/galleryimages/10.webp";
import gal11 from "../../assets/galleryimages/11.webp";
import gal12 from "../../assets/galleryimages/12.webp";
import gal13 from "../../assets/galleryimages/13.webp";
import gal14 from "../../assets/galleryimages/14.webp";
import gal15 from "../../assets/galleryimages/15.webp";
import gal16 from "../../assets/galleryimages/16.webp";
import gal17 from "../../assets/galleryimages/17.webp";
import gal18 from "../../assets/galleryimages/18.webp";
import gal19 from "../../assets/galleryimages/19.webp";
import gal20 from "../../assets/galleryimages/20.webp";
import gal21 from "../../assets/galleryimages/21.webp";
import gal22 from "../../assets/galleryimages/22.webp";
import gal23 from "../../assets/galleryimages/23.webp";
import gal24 from "../../assets/galleryimages/24.webp";
import gal25 from "../../assets/galleryimages/25.webp";
import gal26 from "../../assets/galleryimages/26.webp";
import gal27 from "../../assets/galleryimages/27.webp";
import gal28 from "../../assets/galleryimages/28.webp";
import gal29 from "../../assets/galleryimages/29.webp";
import gal30 from "../../assets/galleryimages/30.webp";
import gal31 from "../../assets/galleryimages/31.webp";
import gal32 from "../../assets/galleryimages/1.webp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faTimes } from "@fortawesome/free-solid-svg-icons";

const images = [
  gal1,
  gal2,
  gal3,
  gal4,
  gal5,
  gal6,
  gal7,
  gal8,
  gal9,
  gal10,
  gal11,
  gal12,
  gal13,
  gal14,
  gal15,
  gal16,
  gal17,
  gal18,
  gal19,
  gal20,
  gal21,
  gal22,
  gal23,
  gal24,
  gal25,
  gal26,
  gal27,
  gal28,
  gal29,
  gal30,
  gal31,
  gal32,
];

const DesktopGallary = () => {
  const galleryRef = useRef(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  // Duplicate images for infinite loop
  const extendedImages = [...images.slice(-4), ...images, ...images.slice(0, 4)];

  // Handle the scroll event
  const handleScroll = () => {
    const element = galleryRef.current;
    if (!element) return;

    const totalWidth = element.scrollWidth - element.clientWidth;
    const buffer = element.clientWidth / 2; // adjust this value as needed

    if (element.scrollLeft < buffer) {
      element.scrollLeft = totalWidth / 3;
    } else if (element.scrollLeft > totalWidth - buffer) {
      element.scrollLeft = totalWidth / 3 - buffer;
    }
  };
  

  useEffect(() => {
    // Set initial scroll position after first render
    const element = galleryRef.current;
    if (element) {
      const start = element.scrollWidth / 3;
      element.scrollLeft = start;
    }
  }, []);


  const scrollLeft = () => {
    if (galleryRef.current) {
      // If at the start, jump to the end
      if (galleryRef.current.scrollLeft === 0) {
        galleryRef.current.scrollTo({ left: galleryRef.current.scrollWidth, behavior: "smooth" });
      } else {
        galleryRef.current.scrollBy({ left: -300, behavior: "smooth" });
      }
    }
  };

  const scrollRight = () => {
    if (galleryRef.current) {
      // If at the end, jump to the start
      if (galleryRef.current.scrollWidth <= galleryRef.current.scrollLeft + galleryRef.current.clientWidth) {
        galleryRef.current.scrollTo({ left: 0, behavior: "smooth" });
      } else {
        galleryRef.current.scrollBy({ left: 300, behavior: "smooth" });
      }
    }
  };


  const openImage = (extendedIndex) => {
    // Adjust the index for the extended array
    const actualIndex = (extendedIndex + images.length - 4) % images.length;
    setSelectedImageIndex(actualIndex);
  };
  

  const closeImage = () => {
    setSelectedImageIndex(null);
  };

  const showNext = () => {
    if (selectedImageIndex < images.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };

  const showPrevious = () => {
    if (selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };

  return (
    <div className={styles.sectionDesktop + " section "} ref={galleryRef} onScroll={handleScroll}>
      <FontAwesomeIcon
        icon={faAngleLeft}
        className={styles.leftArrow}
        onClick={scrollLeft}
      />
      <FontAwesomeIcon
        icon={faAngleRight}
        className={styles.rightArrow}
        onClick={scrollRight}
      />
        {extendedImages.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Gallery image ${index % images.length + 1}`} // Adjust the index for the extended array
          className={styles.galleryImage}
          onClick={() => openImage(index % images.length)}
        />
      ))}
      {selectedImageIndex !== null && (
        <div className={styles.modal}>
          <FontAwesomeIcon
            icon={faTimes}
            className={styles.close}
            onClick={closeImage}
          />
          <FontAwesomeIcon
            icon={faAngleLeft}
            className={styles.modalLeftArrow}
            onClick={showPrevious}
          />
          <img
            src={images[selectedImageIndex]}
            alt={`Gallery image ${selectedImageIndex + 1}`}
            className={styles.fullScreenImage}
          />
          <FontAwesomeIcon
            icon={faAngleRight}
            className={styles.modalRightArrow}
            onClick={showNext}
          />
        </div>
      )}
    </div>
  );
};

export default DesktopGallary;
