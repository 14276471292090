import React from "react";
import Video360 from "../../components/Video360/Video360";


import "./Plans.module.css";
import Section1 from "../../AllPagesSections/Plans/Section1/Section1";
import Section2 from "../../AllPagesSections/Plans/Section2/Section2";
import Section3 from "../../AllPagesSections/Plans/Section3/Section3";
import Section4 from "../../AllPagesSections/Plans/Section4/Section4";
import Footer from "../../components/Footer/Footer";



const Plans = () => {
  return (
    <main className="main">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Footer />
    </main>
  );
};

export default Plans;
