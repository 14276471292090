import React from "react";
import styles from "./Section3.module.css";
import HomeSection11 from "../../Home/Section11/Section11"

const Section3 = () => {
  return (
 <div>
<HomeSection11 />
 </div>
  );
};

export default Section3;
