import { Route, Routes } from 'react-router-dom';
import Home from "./pages/Home.jsx";
import ClubHouse from "./pages/ClubHouse/ClubHouse.jsx";
import ProjectDetails from './pages/ProjectDetails/ProjectDetails.jsx';
import AboutUs from './pages/AboutUs/AboutUs.jsx';
import ContactUs from './pages/ContactUs/ContactUs.jsx';
import Plans from './pages/Plans/Plans.jsx';
import Privacy from './pages/privacy/privacy.jsx';
import Term from './pages/terms/term.jsx';
import Droneview from './pages/Video360/View360.jsx';
import General from './pages/General/General.jsx';
import Legal from './pages/Legal/Legal.jsx';
import Thankyou from './components/thankyou/thankyou.jsx';
import SiteVisitThankyou from './components/site-visit-thankyou/site-visit-thankyou.jsx';
import BanjaraHills from './landingPages/banjaraHills/banjaraHills.jsx';
import Gachibowli from "./landingPages/Gachibowli/Gachibowli.jsx"
import JubileeHills from "./landingPages/forJubilee/forJubilee.jsx"

function MainContent() {

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/amenities" element={<ClubHouse />} />
      <Route path="/ProjectDetails" element={<ProjectDetails />} />
      <Route path="/AboutUs" element={<AboutUs />} />
      <Route path="/ContactUs" element={<ContactUs />} />
      <Route path="/Plans" element={<Plans />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/terms-of-services" element={<Term />} />
      <Route path="/droneview" element={<Droneview />} />
      <Route path="/General" element={<General />} />
      <Route path="/Legal" element={<Legal />} />
      <Route path="/thankyou" element={<Thankyou />} />
      <Route path="/Flats-in-Banjara-Hills" element={<BanjaraHills />} />
      <Route path="/Flats-in-Gachibowli" element={<Gachibowli />} />
      <Route path="/Flats-in-Jubilee-Hills" element={<JubileeHills />} />
      <Route path="/sitevisit-thankyou" element={<SiteVisitThankyou />} />

    </Routes>
  );
}

export default MainContent;
