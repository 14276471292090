// Header.jsx
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Header.module.css";
import ContactPopup from "../contactPopup/ContactPopup"; // Update with the correct path

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [action, setAction] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  const handleScroll = () => {
    let currentScrollTop = window.scrollY;
    if (currentScrollTop < lastScrollTop) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
    setLastScrollTop(currentScrollTop);
  };

  const toggleMenu = () => {
    setIsOpen((prevState) => !prevState);
    if (window.innerWidth <= 768) {
      if (!isOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleDroneViewClick = () => {
    if (localStorage.getItem('formSubmittedForDroneView')) {
      navigate("/droneview");
    } else {
      setAction('droneview');
      setIsPopupOpen(true);
    }
  };

  const handleFormSubmitSuccess = () => {
    if (action === 'droneview') {
      localStorage.setItem('formSubmittedForDroneView', 'true');
      navigate("/droneview");
    }
  };

  return (
    <div className={`mainParent ${isVisible ? "" : styles.hidden}`}>
      <header className={`${styles.header} row px-lg-5 `}>
        <div className={`${styles.mobileHeader} col-sm-12 col-12 col-md-3 col-lg-3 px-4`}>
          <a href="/" className={styles.Amaranthine}>
            Amaranthine
          </a>
          <button className={`${styles.button}`} onClick={toggleMenu}>
            {isOpen ? "✕" : "☰"}
          </button>
        </div>
        <div className={styles.headerDesktop + " col-md-8 col-lg-8 "}>
          <nav className={`${styles.nav} ${isOpen ? styles.open : ""}`}>
            <NavButton
              to="/"
              label="Home"
              isActive={location.pathname === "/"}
            />
            <NavButton
              to="/Amenities"
              label="Amenities"
              isActive={location.pathname === "/Amenities"}
            />
            <NavButton
              to="/ProjectDetails"
              label="Project Details"
              isActive={location.pathname === "/ProjectDetails"}
            />
            <NavButton
              to="/Plans"
              label="Plans"
              isActive={location.pathname === "/Plans"}
            />
            <NavButton
              to="/AboutUs"
              label="About Us"
              isActive={location.pathname === "/AboutUs"}
            />
            <NavButton
              to="/ContactUs"
              label="Contact Us"
              isActive={location.pathname === "/ContactUs"}
            />
            <NavButton
              to="#"
              label="Drone View"
              onClick={handleDroneViewClick}
            />
          </nav>
        </div>
      </header>
      <ContactPopup isOpen={isPopupOpen} togglePopup={togglePopup} onSubmitSuccess={handleFormSubmitSuccess} />
    </div>
  );
};

const NavButton = ({ to, label, isActive, onClick }) => {
  return (
    <a
      href={to}
      className={`${styles.link} ${isActive ? styles.active : ""}`}
      onClick={onClick}
    >
      {label}
    </a>
  );
};

export default Header;
