import React from "react";
import styles from "./Section7.module.css";
import useScrollZoom from "../../../components/useScrollZoom";

const Section7 = () => {
  const sectionRef = useScrollZoom();

  return (
    <div ref={sectionRef} className={`${styles.section} section`}>
      <div className={`overlay`}>
        <div className={``}>
          <h3 className={` heading ` + styles.heading}>
            Wrap-around Balconies{" "}
          </h3>
          <h3 className={`textBody`}>
            These flats in Khajaguda have extensive 42 X 6’6 balconies where you
            can enjoy a 270° aerial view of the city. Whether you prefer hosting
            outdoor parties, practising yoga, or simply enjoying a private
            retreat where you can relax, unwind, and soak up the sun's rays,
            relish it all on your high-rise Wrap-around balconies.
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Section7;
