import React from "react";
import styles from "./Section6.module.css";
import useScrollZoom from "../../../components/useScrollZoom";

const Section7 = () => {
  const sectionRef = useScrollZoom();

  return (
    <div ref={sectionRef} className={`${styles.section} section`}>
      <div className={`overlay`}>
        <div className={``}>
          <h1 className={` heading ` + styles.heading}>
            Exquisite Concierge Service
          </h1>
          <h2 className={`textBody`}>
            Bid farewell to mundane tasks, and embrace a life of unmatched
            indulgence at QUAMBIANT AMARANTHINE, apartments located near Banjara
            Hills. Count on our knowledgeable concierge services expertly manage
            your daily errands with our Valet services, home automation, and
            more.
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Section7;
