import React from "react";
import styles from "./Section15.module.css";
import Section16 from "../Section16/Section16";
import CustomMap from "../.././../assets/CustomMap.jpg";

const Section15 = () => {
  return (
    <div className={styles.section + " section "}>
      <div className={styles.overlay + " overlay "}>
        <h1 className={styles.text}> LOCATION ADVANTAGES </h1>
        <h2 className="textBody">
        Living in Amaranthine is living in a prestigious neighbourhood. These apartments in  Banjara Hills, Hyderabad are rightly positioned between Hitech City, Financial District and Gachibowli making your place of abode one of the most accessible locations from tech zones.
        </h2>

        <div className={styles.reveseCol + " row "}>
          <div className="col-12 col-md-6 col-lg-6">
            <Section16 />
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <img src={CustomMap} alt="map" className={styles.mapImage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section15;
