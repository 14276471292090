import React, { useState } from "react";
import styles from "./DeskCaurasal.module.css";
// import Carousel from "react-multi-carousel";
// import ReactDOM from 'react-dom';

// import "react-multi-carousel/lib/styles.css";
// import image1 from "../../assets/caurasalImages/1AMPHITHEATRE.webp";
// import image2 from "../../assets/caurasalImages/2BarbequeCorner.webp";
// import image3 from "../../assets/caurasalImages/3cricketnetpractice.webp";
// import image4 from "../../assets/caurasalImages/4cyclerscourt.webp";
// import image5 from "../../assets/caurasalImages/5Halfbasketballcourt.webp";
// import image6 from "../../assets/caurasalImages/6joggingtrack.webp";
// import image7 from "../../assets/caurasalImages/7KIDSPLAYAREA.webp";
// import image8 from "../../assets/caurasalImages/8MEDITATIONCENTRE.webp";
// import image9 from "../../assets/caurasalImages/9OUTDOORGYM.webp";
// import image10 from "../../assets/caurasalImages/10siniorcitizenzone.webp";
// import image11 from "../../assets/caurasalImages/11SKATINGRING.webp";
// import image12 from "../../assets/caurasalImages/12tenniscourt.webp";

// const responsive = {
//   superLargeDesktop: {
//     breakpoint: { max: 4000, min: 3000 },
//     items: 5,
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 5,
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 2,
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//   },
// };

// const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12];
// const imageTexts = ["AMPHITHEATRE", "Barbeque Corner", "Cricket Net Practice", "Cyclers Court", "Half Basketball Court", "Jogging track", "KIDS PLAY AREA", "MEDITATION CENTRE", "OUTDOOR GYM", "Senior citizen zone", "SKATING RING", "Tennis Court"];

// const DeskCaurasal = () => {
//   const [showModal, setShowModal] = useState(false);
//   const [selectedImage, setSelectedImage] = useState(null);

//   const openModal = (image) => {
//     setSelectedImage(image);
//     setShowModal(true);
//     document.body.style.overflow = 'hidden'; // Lock the scroll
//   };

//   const closeModal = () => {
//     setSelectedImage(null);
//     setShowModal(false);
//     document.body.style.overflow = 'auto'; // Unlock the scroll
//   };

//   return (
//     <div>
//       <div>
//         <div>
//           <Carousel className={`${styles.tileContainer}`} responsive={responsive}>
//             {images.map((image, index) => (
//               <div
//                 className={`${styles.tile} ${
//                   index % 2 === 0 ? styles.tileOdd : styles.tileEven
//                 }`}
//                 style={{
//                   backgroundImage: `url(${image})`,
//                   backgroundSize: "cover",
//                   backgroundPosition: "center",
//                 }}
//                 key={index}
//                 onClick={() => openModal(image)}
//               >
//                 <div className={styles.cardText}>{imageTexts[index]}</div>
//               </div>
//             ))}
//           </Carousel>
//         </div>
//       </div>
//       {showModal && ReactDOM.createPortal(
//         <div className={styles.modal}>
//           <div className={styles.modalContent}>
//             <img src={selectedImage} alt="Full View" className={styles.fullImage} />
//             <button className={styles.closeButton} onClick={closeModal}>X</button>
//           </div>
//         </div>,
//         document.body // This renders the modal directly within the body
//       )}
//     </div>
//   );
// };

// export default DeskCaurasal;

import img1 from "../../assets/AmenitiesIcon/1.svg";
import img2 from "../../assets/AmenitiesIcon/2.svg";
import img3 from "../../assets/AmenitiesIcon/3.svg";
import img4 from "../../assets/AmenitiesIcon/4.svg";
import img5 from "../../assets/AmenitiesIcon/5.svg";
import img6 from "../../assets/AmenitiesIcon/6.svg";
import img7 from "../../assets/AmenitiesIcon/7.svg";
import img8 from "../../assets/AmenitiesIcon/8.svg";
import img9 from "../../assets/AmenitiesIcon/9.svg";
import img10 from "../../assets/AmenitiesIcon/10.svg";
import img11 from "../../assets/AmenitiesIcon/11.svg";
import img12 from "../../assets/AmenitiesIcon/12.svg";

const DeskAmenities = () => {
  const data = [
    { image: img1, label: "Meditation Centre" },
    { image: img2, label: "Tennis Court" },
    { image: img3, label: "Kid's Play Area" },
    { image: img4, label: "Half Basketball Court" },
    { image: img5, label: "Amphitheatre" },
    { image: img6, label: "Jogging Track" },
    { image: img7, label: "Cyclers Court" },
    { image: img8, label: "Barbeque Corner" },
    { image: img9, label: "Cricket Net Practice" },
    { image: img10, label: "Senior Citizen Zone" },
    { image: img11, label: "Skating Ring" },
    { image: img12, label: "Outdoor gym" },
  ];

  return (
    <div className={styles.AmenitiesDesk + "  "}>
{data.map((item, index) => (
        <div key={index} className={styles.titleLabel}>
            <img src={item.image} alt="icon" className={styles.icons}/>
            <p>{item.label}</p>
        </div>
      ))}
    </div>
  );
};

export default DeskAmenities;
