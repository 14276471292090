import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import styles from "./Section9.module.css";
import useScrollZoom from "../../../components/useScrollZoom";

const Section9 = () => {
  const sectionRef = useScrollZoom();

  return (
    <div ref={sectionRef} className={`${styles.section} section`}>
      <div className={`${styles.overlay} overlay`}>
        <div className={``}>
          <h2 className={`${styles.heading} heading`}>club house</h2>
          <h3 className={`${styles.textBody} textBody`}>
            Relish your leisure time with amenities, or stay productive at the
            business centre. Form a deeper bond with your loved ones through a
            multitude of activities.
          </h3>
        </div>
        <div className={`${styles.right_container}`}>
          <div className={`${styles.text}`}>
            <h3 className={styles.headingText}>38,000</h3>
            <p className={styles.para}>SQFT</p>
          </div>
          <div className={`${styles.text}`}>
            <h3 className={styles.headingText}>50+</h3>
            <p className={styles.para}>AMENITIES</p>
          </div>
          <div className={styles.buttonContainer}>
            <a href="/amenities" className={`${styles.button}`}>
              Explore amenities
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section9;
