import React from 'react'
import Video360 from '../../components/Video360/Video360'

function droneview() {
  return (
    <div>
    <Video360 />
    </div>
  )
}

export default droneview