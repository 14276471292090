import React from "react";


import "./ClubHouse.module.css";
import Section1 from "../../AllPagesSections/ClubHouse/Section1/Section1";
import Section2 from "../../AllPagesSections/ClubHouse/Section2/Section2";
import Section3 from "../../AllPagesSections/ClubHouse/Section3/Section3";
import Section4 from "../../AllPagesSections/ClubHouse/Section4/Section4";
import Footer from "../../components/Footer/Footer";


const ClubHouse = () => {
  return (
    <main className="main">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Footer />
    </main>
  );
};

export default ClubHouse;
