import React from "react";
import styles from "./Section4.module.css";
import VideoComponent from "../../Home/Section12/Section12.jsx"

const Section4 = () => {
  return (
    <div className={styles.section + " section "}>

        <VideoComponent />

    </div>
  );
};

export default Section4;

