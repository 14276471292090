import React, { useEffect } from "react"; 
import styles from "./Disclaimer.module.scss";
import 'font-awesome/css/font-awesome.min.css'; 



const DisclaimerPopup = ({ onClose }) => {
    useEffect(() => {
        const originalOverflow = document.body.style.overflow;  // Save the original overflow
        const originalPosition = document.body.style.position; // Save the original position
        
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';  // Add this line
    
        return () => {
            document.body.style.overflow = originalOverflow;   // Restore the original overflow
            document.body.style.position = originalPosition;  // Restore the original position
        };
    }, []);
    
  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupContent}>
      <i className={`${styles.closeIcon} fas fa-times`} onClick={onClose}></i>
        <h2 className={styles.title}>DISCLAIMER</h2>
        <p>
          The content of this website is for general information purposes only.
          While enough care is taken by Quambiant to ensure that information on
          the website is up to date, accurate and correct, readers are requested
          to make their independent enquiry before relying upon the same. In no
          event will Quambiant offer any warranty on the information made
          available or be liable for any loss or damage including without
          limitation, indirect or consequential loss or damage in connection
          with the use of information on this website. By using or accessing the
          website, you agree with the Disclaimer without any qualification or
          limitation.
        </p>
        <button onClick={onClose}>I AGREE - Continue to Site</button>
        <div className={styles.footerInfo}>
          <span className={styles.quambiant}>Quambiant</span>
          <br />
          <span><a href="mailto:sales@quambiant.com">sales@quambiant.com</a> </span>
          <span>
              | Tel:
              +91 9774 123456 | +91 8876 123456
          </span>
          <br />
          <span>RERA NO: P02400006713</span>
        </div>
      </div>
    </div>
  );
};

export default DisclaimerPopup;
