import React from "react";
import styles from "./Section10.module.css";
import DeskCaurasal from "../../../components/DeskCaurasal/DeskCaurasal";
import MobCaurasal from "../../../components/MobCaurasal/MobCaurasal";

const Section10 = () => {
  return (
    <div className={`${styles.section} section`}>
      <div className={`${styles.overlay}`}>
        <div className={styles.textContainer}>
          <h2 className={` heading ` + styles.heading}>Outdoor Amenities</h2>
          <h3 className={`textBody pb-4`}>
            These flats for sale in Khajaguda, Hyderabad have a lot of options
            to choose from, spend your time with your loved ones at our outdoor
            amenities. Whether it’s the Barbeque Corner to have a delightful
            time with your family or the meditation zone to find zen. We have it
            all.
          </h3>
        </div>
        <div className={styles.DeskCaurasal}>
          <DeskCaurasal />
        </div>
        <div className={styles.MobCaurasal}>
          <MobCaurasal />
        </div>
      </div>
    </div>
  );
};

export default Section10;
