import React from "react";
import styles from "./Section17.module.css";

const Section17 = () => {
  return (
    <div className={`${styles.section} section`}>
      <div className={`${styles.overlay} overlay`}>
        <div className={`${styles.container}`}>
          <h1 className={`heading`}>Plans</h1>
          <h2 className={"textBody"}>
            Own a place that screams sophistication in every detail. Immerse
            yourself in the exclusivity of larger-than-life bedrooms,
            wrap-around balconies, a private home theatre or your hobby room.
            With an array of 3/4/5 BHK to choose from!
          </h2>
          <a href="/Plans" className={`${styles.myButton}`}>
            Explore Plans
          </a>
        </div>
      </div>
    </div>
  );
};

export default Section17;
