import React from "react";
import { Helmet } from 'react-helmet';


import "./ContactUs.module.css";
import Section1 from "../../AllPagesSections/ContactUs/Section1/Section1";
import Section2 from "../../AllPagesSections/ContactUs/Section2/Section2";
import Footer from "../../components/Footer/Footer";

const ContactUs = () => {
  return (
    <main className="main">
      <Section1 />
      <Section2 />
      <Footer />
    </main>
  );
};

export default ContactUs;
