import React from "react";
import styles from "./Section8.module.css";
import useScrollZoom from "../../../components/useScrollZoom";

const Section8 = () => {
  const sectionRef = useScrollZoom();

  return (
    <div ref={sectionRef} className={`${styles.section} section`}>
      <div className={`overlay`}>
        <div className={``}>
          <h3 className={` heading ` + styles.heading}>Private Home Theatre</h3>
          <h5 className={`textBody`}>
            Picture a room converted into a cinematic abode, combining
            audiovisual technology with luxurious furnishings. Watch & enjoy
            your favourite movies, TV shows, or gaming adventures on a large
            high-definition screen with immersive surround sound at home.
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Section8;
