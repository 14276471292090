import React from "react";
import logo from "../../../assets/images/bacgrounds/logo.webp";
import useScrollZoom from "../../../components/useScrollZoom";
import styles from "./Section1.module.css";

function Section1() {
  const sectionRef = useScrollZoom();

  return (
    <div ref={sectionRef} className={`${styles.section} section`}>
      <div className={`${styles.overlay} overlay`}>
        <img src={logo} alt="Logo" className={styles.logo + " position-absolute "} />
        <div className="homeTextArea">
          <h2 className={styles.headingTwo}>Hyderabad’s First</h2>
          <h1 className={styles.headingOne}>Suite-Styled Living Spaces</h1>
          <h2 className={styles.headingFive}>Project Details</h2>
        </div>
      </div>
    </div>
  );
}

export default Section1;
