import React, { useRef } from "react";
import styles from "./Section2.module.css";
import useScrollZoom from "../../../components/useScrollZoom";
import useColorChangeOnScroll from "../../../components/useColorChangeOnScroll";

const Section2 = () => {
  const sectionRef = useScrollZoom();
  const textRef = useRef(null);

  useColorChangeOnScroll(sectionRef, textRef);

  return (
    <div ref={sectionRef} className={`${styles.section} section`}>
      <div className={`${styles.overlay} overlay`}>
        <div className={`${styles.text}`}>
          <h3 ref={textRef} className={`${styles.textBody} textBody`}>
            With 20 years of experience, Quambiant Design Build focuses on
            Hyderabad and Bengaluru. We have completed 8+ properties and 4+
            fully sold-out industrial parks, building a growing residential
            community of 5000+ members. Our mission is to provide exceptional
            lifestyle options and premium experiences, elevating every moment.
            Meticulous design, superior craftsmanship, and thoughtful amenities
            define our approach. Trust our reputation for delivering outstanding
            projects.
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Section2;
