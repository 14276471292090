import React, { useRef } from "react";
import styles from "./Section3.module.css";
import useScrollZoom from "../../../components/useScrollZoom";

const Section3 = () => {
  const sectionRef = useScrollZoom();

  return (
    <div ref={sectionRef} className={`${styles.section} section`}>
      <div className={`overlay`}>
        <div className={``}>
          <h1 className={" heading " + styles.heading}>
            Mono-tower Exclusivity
          </h1>
          <h1 className={`subHeading`}>
            More scenic city views and no visible neighbours
          </h1>
          <h2 className={`textBody`}>
            The pinnacle of living at Quambiant Amaranthine Tower; Experience
            uninterrupted views of the cityscape with a mono tower, where you
            exclusively overlook the skyline. Choose your panoramic view of the
            city, with views of the Golconda Fort or the Malkam Cheruvu and
            Raidurgam Cheruvu.
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Section3;
