import React from "react";
import logo from "../../../assets/images/bacgrounds/logo.webp";
import video from "../../../assets/videos/amaranthine website clubhouse video.mp4";
import styles from "./Section1.module.css";

function AllSection1() {
  return (
    <div className={`${styles.section} section`}>
      <video className={styles.videoBackground} autoPlay loop muted playsInline>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className={`${styles.overlay} overlay`}>
        <img src={logo} alt="Logo" className={styles.logo} />
        <div className="homeTextArea">
          <h3 className={styles.headingTwo}>Hyderabad’s First</h3>
          <h2 className={styles.headingOne}>Suite-Styled Living Spaces</h2>
          <h4 className={styles.headingFive}>Club house</h4>
        </div>
      </div>
    </div>
  );
}

export default AllSection1;
