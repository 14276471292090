import React from "react";
import styles from "./Section4.module.css";
import HomeSection10 from "../../Home/Section10/Section10"

const Section4 = () => {
  return (
    <div>

    <HomeSection10/>
    </div>
  );
};

export default Section4;
