import React from 'react';




import "./Home.css";
import AllSection1 from "../AllPagesSections/Home/AllSection1/AllSection1";
import AllSection2 from "../AllPagesSections/Home/AllSection2/AllSection2";
import Section3 from "../AllPagesSections/Home/Section3/Section3";
import Section4 from "../AllPagesSections/Home/Section4/Section4";
import Section5 from "../AllPagesSections/Home/Section5/Section5";
import Section6 from "../AllPagesSections/Home/Section6/Section6";
import Section7 from "../AllPagesSections/Home/Section7/Section7";
import Section8 from "../AllPagesSections/Home/Section8/Section8";
import Section9 from "../AllPagesSections/Home/Section9/Section9";
import Section10 from "../AllPagesSections/Home/Section10/Section10";
import Section11 from "../AllPagesSections/Home/Section11/Section11";
import Section12 from "../AllPagesSections/Home/Section12/Section12";
import Section13 from "../AllPagesSections/Home/Section13/Section13";
import Section14 from "../AllPagesSections/Home/Section14/Section14";
import Section15 from "../AllPagesSections/Home/Section15/Section15";
import Section16 from "../AllPagesSections/Home/Section16/Section16";
import Section17 from "../AllPagesSections/Home/Section17/Section17";
import Section18 from "../AllPagesSections/Home/Section18/Section18";
import Footer from "../components/Footer/Footer";
import useTrackingPixel from "../Hooks/useTrackingPixel";


const Home = () => {
  // useTrackingPixel(17614);

  return (

    <main className=" main ">
      <AllSection1 />
      <AllSection2 />
      <Section11 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 />
      <Section12 />
      <Section13 />
      {/* <Section14 /> */}
      <Section15 />
      {/* <Section16 /> */}
      <Section17 />
      <Section18 />
      <Footer />
    </main>
  );
};

export default Home;