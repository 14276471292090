import React, { useRef } from "react";
import styles from "./Section2.module.css";
import useScrollZoom from "../../../components/useScrollZoom";
import useColorChangeOnScroll from "../../../components/useColorChangeOnScroll";

const Section2 = () => {
    const sectionRef = useScrollZoom();
    const textRef = useRef(null);

    return (
        <div ref={sectionRef} className={`${styles.section} section`}>
            <div className={`${styles.overlay} overlay`}>
                <div className={`${styles.text}`}>
                    <h2 ref={textRef} className={`${styles.textBody} textBody`}>
                        Curious what the view from your balcony would look like? Explore the 360° panoramic view from your balcony here. 
                    </h2>
                    <a href="/droneview" className={styles.viewButton}> 
                            Explore 360° View
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Section2;
