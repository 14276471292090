import React from "react";
import styles from "./Section5.module.css";
import FormMap from "../../../components/FormMap/FormMap"

const Section4 = () => {
  return (
    <main>
     <FormMap/>
    </main>
  );
};

export default Section4;
