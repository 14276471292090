import React from "react";
import styles from "./Section2.module.css";
import FormMap from "../../../components/FormMap/FormMap";

const Section2 = () => {
  return (
    <main>
      <FormMap/>
    </main>
  );
};

export default Section2;
