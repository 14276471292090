import React, { useState, useRef } from 'react';
import styles from './MobCaurasal.module.css';

import image1 from "../../assets/caurasalImages/1AMPHITHEATRE.webp";
import image2 from "../../assets/caurasalImages/2BarbequeCorner.webp";
import image3 from "../../assets/caurasalImages/3cricketnetpractice.webp";
import image4 from "../../assets/caurasalImages/4cyclerscourt.webp";
import image5 from "../../assets/caurasalImages/5Halfbasketballcourt.webp";
import image6 from "../../assets/caurasalImages/6joggingtrack.webp";
import image7 from "../../assets/caurasalImages/7KIDSPLAYAREA.webp";
import image8 from "../../assets/caurasalImages/8MEDITATIONCENTRE.webp";
import image9 from "../../assets/caurasalImages/9OUTDOORGYM.webp";
import image10 from "../../assets/caurasalImages/10siniorcitizenzone.webp";
import image11 from "../../assets/caurasalImages/11SKATINGRING.webp";
import image12 from "../../assets/caurasalImages/12tenniscourt.webp";

const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12];
const imageText = ["AMPHITHEATRE", "Barbeque Corner", "Cricket Net Practice", "Cyclers Court", "Half Basketball Court", "Jogging track", "KIDS PLAY AREA", "MEDITATION CENTRE", "OUTDOOR GYM", "Senior citizen zone", "SKATING RING", "Tennis Court"];

const RotatingCarousel = () => {
  const [rotate, setRotate] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [currentRotate, setCurrentRotate] = useState(0);

  const containerRef = useRef(null);

  // Calculate the rotation angle and translation distance based on the number of images
  const rotationAngle = 360 / images.length;
  const translationDistance = 400; // You can adjust this value if needed

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    setDragging(true);
    setCurrentRotate(rotate);
  };

  const handleTouchMove = (e) => {
    if (dragging) {
      const x = e.touches[0].clientX;
      const diff = (x - startX) * 180 / window.innerWidth; // you can adjust sensitivity here
      const newRotate = currentRotate + diff;
      setRotate(newRotate);
    }
  };

  const handleTouchEnd = () => {
    setDragging(false);
    const index = Math.round(rotate / rotationAngle);
    setRotate(index * rotationAngle);
  };

  return (
    <div
      className={styles.container}
      ref={containerRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div
        className={styles.carousel}
        style={{ transform: `rotateY(${rotate}deg)` }}
      >
        {images.map((image, index) => (
          <div
            className={styles.carouselItem}
            style={{
              backgroundImage: `url(${image})`,
              transform: `rotateY(${index * rotationAngle}deg) translateZ(${translationDistance}px)`,
            }}
            key={index}
          >
            <div className={styles.cardText}>{imageText[index]}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RotatingCarousel;
