import React from "react";
import styles from "./Section18.module.css";
import FormMap from "../../../components/FormMap/FormMap";

const Section18 = () => {
  return (
    <div className={`${styles.section} section`}>
      <div className={`${styles.overlay} overlay`}>
        <FormMap />
      </div>
    </div>
  );
};

export default Section18;
