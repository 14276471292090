import React from "react";
import styles from "./Section3.module.css";

const Section3 = () => {
  return (
    <div className={`${styles.section} section`}>
      <div className={`overlay text-center`}>
        <h1 className={styles.textMaster + " row text-center "}>MASTER PLAN</h1>
          <div className={`${styles.myImage}`} />
      </div>
    </div>
  );
};

export default Section3;


