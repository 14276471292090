import React, { useRef } from "react";
import styles from "./Section4.module.css";
import useScrollZoom from "../../../components/useScrollZoom";

const Section4 = () => {
  const sectionRef = useScrollZoom();

  return (
    <div ref={sectionRef} className={`${styles.section} section`}>
      <div className={`overlay`}>
        <div className={""}>
          <h1 className={" heading " + styles.heading}>
            Zero Traffic Clubhouse
          </h1>
          <h1 className={`subHeading`}>
             Unparalleled Space for Exclusive Relaxation
          </h1>
          <h2 className={`textBody`}>
            Enjoy leisure time with your loved ones in a truly exclusive
            setting. Our expansive 38,000 Sq Ft Clubhouse will be shared with a
            close-knit community of fewer than 500 families. Whether it’s the
            Sauna or the stunning rooftop infinity swimming pool, there’s space
            to savour the moments slowly.
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Section4;
