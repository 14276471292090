import React from "react";


import "./AboutUs.module.css";

import Section1 from "../../AllPagesSections/AboutUs/Section1/Section1";
import Section2 from "../../AllPagesSections/AboutUs/Section2/Section2";
import Section3 from "../../AllPagesSections/AboutUs/Section3/Section3";
import Section4 from "../../AllPagesSections/AboutUs/Section4/Section4";
import Section5 from "../../AllPagesSections/AboutUs/Section5/Section5";
import Footer from "../../components/Footer/Footer";

const AboutUs = () => {
  return (
    <main className="main">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Footer />

    </main>
  );
};

export default AboutUs;
