import React from "react";
import styles from "./Section4.module.css";
import image1 from "../../../assets/images/bacgrounds/gal2.jpg";

const cards = [
  {
    title: "AR. NARENDRANATH",
    text: "Narendranath is an esteemed design expert. With an IIT Roorkee background, he brings profound knowledge and expertise to each project. Quambiant Design Build, founded by Ar. Narendranath is a gateway to an extraordinary design journey; collaborate with this master architect for design experience & unparalleled results.",
    image: image1,
  },
  {
    title: "YOGENDRANATH",
    text: "Yogendranath's innate understanding of precision shines through in his mindful living spaces. With expertise in Automotive and Industrial design, he effortlessly simplifies complex structures. As a pillar of Quambiant Design Build, he has played a pivotal role in projects like Project One and Amaranthine, showcasing his unwavering commitment to excellence.",
    image: image1,
  },
  {
    title: "ARASU",
    text: "Arasu, an esteemed Electrical Engineer with 37 years of expertise, brings unrivaled excellence to the realm of electrical designing and planning. As an integral part of Quambiant Design Build, his craftsmanship shines through in prestigious projects across Hyderabad, Bangalore, Chennai, Pune, and even the Andaman & Nicobar Islands.  With his experience and contribution, our projects consistently thrive and flourish.",
    image: image1,
  },
  {
    title: "SHIVSAGAR",
    text: "Shivsagar, a Civil Engineer with a Master's degree and over 25 years of experience brings unparalleled expertise to Structure Design & Planning. His unwavering commitment to self-improvement and impressive skill set reflect his profound contributions to civil engineering. As a beacon of inspiration, Shivsagar's extensive service under the Ministry of Urban Affairs has laid a strong foundation of knowledge, empowering professionals in the field.",
    image: image1,
  },
];

const Section4 = () => {
  return (
    <div className={`${styles.section} section`}>
      <div className={`${styles.overlay} overlay container`}>
        <h2 className={"heading"}>Meet the Experts</h2>
        <div className="row">
          {cards.map((card, index) => (
            <div key={index} className={`${styles.card} col-md-6 col-12`}>
              <h1 className={styles.name}>{card.title}</h1>
              <p>{card.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section4;