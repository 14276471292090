import React, { useEffect, useRef } from "react";
import logo from "../../../assets/images/bacgrounds/logo.webp";
import video from "../../../assets/videos/Amaranthine website home page video.mp4";
import styles from "./AllSection1.module.css";

function AllSection1() {
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false; // Indicates the component has unmounted
    };
  }, []);

  return (
    <div className={`${styles.section} section`}>
      <video className={styles.videoBackground} autoPlay loop muted playsInline>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className={`${styles.overlay} overlay`}>
        <img
          src={logo}
          alt="Logo"
          className={`${styles.logo} position-absolute`}
        />
        <div className="homeTextArea">
          <h2 className={styles.headingTwo}>Hyderabad’s First</h2>
          <h1 className={styles.headingOne}>Suite-Styled Living Spaces</h1>
          <h2 className={styles.headingFive}>Club house</h2>
        </div>
      </div>
    </div>
  );
}

export default AllSection1;
