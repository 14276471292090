import React from 'react';
import styles from './Legal.module.scss';

function Legal() {
  return (
    <div className={styles.container}>
                <button className={styles.backButton} onClick={() => window.history.back()}>Go Back</button>

      <h1 className={styles.title}>Legal</h1>
      <p>Amaranthine Living is committed to protecting your privacy. This Privacy policy explains how your personal information is collected and used.</p>
      <p>By accessing or using our services, you signify that you have read, understood, and agree to our collection, storage, use and disclosure of your personal information as described in this policy and our terms of service.</p>
      
      <h2 className={styles.subtitle}>What information do we collect?</h2>
      <p>We collect information from you when you fill out the form on our website:</p>
      <ul className={styles.list}>
        <li>Name</li>
        <li>Email</li>
        <li>Phone no</li>
      </ul>

      <h2 className={styles.subtitle}>How Do We Use The Information We Collect?</h2>
      <p>Any of the information we collect from you may be used in one of the following ways:</p>
      <ul className={styles.list}>
        <li>To personalize your experience (your information helps us to better respond to your individual needs)</li>
        <li>To improve our website (we continually strive to improve our website offerings based on the information and feedback we receive from you)</li>
        <li>To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs)</li>
        <li>To send periodic emails</li>
      </ul>

      <h2 className={styles.subtitle}>How Do We Use Your Email Address?</h2>
      <p>
        By submitting your email address on this website, you agree to receive emails from us. You can cancel your participation in any of these email lists at any time by clicking on the opt-out link or other unsubscribe option that is included in the respective email. We only send emails to people who have authorized us to contact them, either directly, or through a third party. We do not send unsolicited commercial emails, because we hate spam as much as you do. By submitting your email address, you also agree to allow us to use your email address for customer audience targeting on sites like Facebook, where we display custom advertising to specific people who have opted-in to receive communications from us.
      </p>

      <h2 className={styles.subtitle}>How Long Do We Keep Your Information?</h2>
      <p>
        We keep your information only so long as we need it to provide our services to you and fulfill the purposes described in this policy. This is also the case for anyone that we share your information with and who carries out services on our behalf. When we no longer need to use your information and there is no need for us to keep it to comply with our legal or regulatory obligations, we'll either remove it from our systems or depersonalize it so that we can't identify you.
      </p>

      <h2 className={styles.subtitle}>How Do We Protect Your Information?</h2>
      <p>We implement a variety of security measures to maintain the safety of your personal information.</p>

      /* ... other parts of the component ... */

<h2 className={styles.subtitle}>Definitions and key terms:</h2>
<p>
    •  <strong>Cookie:</strong> A small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, and remember information about you such as your language preference or login information.
</p>
<p>
    •  <strong>Company:</strong> When this policy mentions "Company," "we," "us," or "our," it refers to Amaranthine Living, Khajaguda, Hyderabad that is responsible for your information under this Privacy Policy.
</p>
<p>
    •  <strong>Country:</strong> where Amaranthine Living or the owners/founders of Amaranthine Living are based, in this case, is India.
</p>
<p>
    •  <strong>Customer:</strong> refers to the company, organization or person that signs up to use the Amaranthine Living Service to manage the relationships with your consumers or service users.
</p>
<p>
    •  <strong>Device:</strong> Any internet-connected device such as a phone, tablet, computer or any other device that can be used to visit Amaranthine Living and use the services.
</p>
<p>
    •  <strong>IP address:</strong> Every device connected to the Internet is assigned a number known as an Internet protocol (IP) address. These numbers are usually assigned in geographic blocks. An IP address can often be used to identify the location from which a device is connected to the Internet.
</p>
<p>
    •  <strong>Personnel:</strong> refers to those individuals who are employed by Amaranthine Living or are under contract to perform a service on behalf of one of the parties.
</p>
<p>
    •  <strong>Personal Data:</strong> any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.
</p>
<p>
    •  <strong>Service:</strong> refers to the service provided by Amaranthine Living as described in the relative terms (if available) and on this platform.
</p>
<p>
    •  <strong>Third-party service:</strong> refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.
</p>
<p>
    •  <strong>Website:</strong> Amaranthine Living site, which can be accessed via this URL: https://quambiantamaranthine.com/
</p>
<p>
    •  <strong>You:</strong> a person or entity that is registered with Amaranthine Living to use the Services.
</p>



      <h2 className={styles.subtitle}>Contact Us</h2>
      <p>Do get in touch if you have any questions.</p>
      <ul className={styles.list}>
        <li>Via Email: Sales@quambiant.com</li>
      </ul>

      <footer className={styles.footer}>
        Website: <a href="https://quambiantamaranthine.com/" className={styles.link} target="_blank" rel="noopener noreferrer">quambiantamaranthine.com</a>
      </footer>
    </div>
  );
}

export default Legal;
