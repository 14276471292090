import React from 'react';
import styles from './Video360.module.scss';

function Video360() {
    return (
        <div className={styles.section + " section "}>
            <iframe
                src={process.env.PUBLIC_URL + "/Final360/index.htm"}
                width="100%"
                height="100%"
                frameBorder="0"
                allowFullScreen
            ></iframe>
        </div>
    );
}

export default Video360;
