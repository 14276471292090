import React, { useRef } from "react";
import styles from "./Section5.module.css";
import useScrollZoom from "../../../components/useScrollZoom";

const Section5 = () => {
  const sectionRef = useScrollZoom();

  return (
    <div ref={sectionRef} className={`${styles.section} section`}>
      <div className={`overlay`}>
        <div className={``}>
          <h1 className={` heading ` + styles.heading}>world at your feet</h1>
          <h1 className={`subHeading`}>
            Prime Location for Seamless Connectivity
          </h1>
          <h2 className={"textBody"}>
            These suite-styled apartments in Gachibowli, offer seamless access
            to a network of major roads, spanning from Lanco Hill Road to the
            Old Mumbai highway. Enjoy smooth access to essentials like KIMS
            Hospital and Oakridge International School in close proximity.
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Section5;
