import React from "react";
import styles from "./Section3.module.css";
import 'bootstrap/dist/css/bootstrap.min.css';

const stats = [
  [
    { colSize: 4, number: '666+', text: 'Luxury Apartment Created' },
    { colSize: 4, number: '2000+', text: 'Happy Families' },
    { colSize: 4, number: '20,000+', text: 'Walk-Ins' },
  ],
  [
    { colSize: 6, number: '10 Lacs+', text: 'Square Feet Delivered' },
    { colSize: 6, number: '9+', text: 'Project In The Making' },
  ],
];

const Section3 = () => { // Removed the stats prop from here
  return (
    <div className={`${styles.section} section`}>
      <div className={`${styles.overlay} overlay`}>
        <div className={`${styles.sectionContainer} container`}>
          {stats.map((row, rowIndex) => (
            <div className="row justify-content-between" key={rowIndex}>
              {row.map((stat, colIndex) => (
                <div className={`col-md-${stat.colSize}`} key={colIndex}>
                  <h3 className={styles.largeText}>{stat.number}</h3>
                  <p className={styles.smallText}>{stat.text}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section3;