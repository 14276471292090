import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./Section4.module.css";
import Slide1Img1 from "../../../assets/plan-slides/Slide1Img1.png";
import Slide1Img2 from "../../../assets/plan-slides/Slide1Img2.png";
import Slide2Img1 from "../../../assets/plan-slides/Slide2Img1.png";
import Slide2Img2 from "../../../assets/plan-slides/Slide2Img2.png";
import Slide3Img1 from "../../../assets/plan-slides/Slide3Img1.png";
import Slide3Img2 from "../../../assets/plan-slides/Slide3Img2.png";
import Slide4Img1 from "../../../assets/plan-slides/Slide4Img1.png";
import Slide4Img2 from "../../../assets/plan-slides/Slide4Img2.png";
import Slide5Img1 from "../../../assets/plan-slides/Slide5Img1.png";
import Slide5Img2 from "../../../assets/plan-slides/Slide5Img2.png";
import Slide6Img1 from "../../../assets/plan-slides/Slide6Img1.png";
import Slide6Img2 from "../../../assets/plan-slides/Slide6Img2.png";
import Slide7Img1 from "../../../assets/plan-slides/Slide7Img1.png";
import Slide7Img2 from "../../../assets/plan-slides/Slide7Img2.png";
import Slide8Img1 from "../../../assets/plan-slides/Slide8Img1.png";
import Slide8Img2 from "../../../assets/plan-slides/Slide8Img2.png";

const slides = [
  {
    src: Slide1Img1,
    src2: Slide1Img2,
    alt: "Slide 1",
    alt2: "Another Slide 1",
    MainTitle: "WEST FACING  |  2250 SQFT",
    MainTitleEnd: "A1",
    labels: [
      "1. LIVING & DINING",
      "2. SIT-OUT",
      "3. MASTER BEDROOM",
      "4. TOILET",
      "5. KITCHEN",
      "6. UTILITY",
      "7. BEDROOM",
      "8. TOILET",
      "9. TOILET",
      "10. BEDROOM",
      "11. SIT-OUT"
    ],
    values: [
      "31’3” X 11’0”",
      "6’6” X 11’0”",
      "14’11” X 11’0”",
      "7’1” X 7’8”",
      "10’3” X 11’0”",
      "3’11” X 11’0”",
      "12’0” X 11’0”",
      "4’11” X 7’8”",
      "4’11” X 7’8”",
      "14’0” X 11’0”",
      "39’2” X 6’6”"
    ]
  }
,
{
  src: Slide2Img1,
  src2: Slide2Img2,
  alt: "Slide 2",
  alt2: "Another Slide 2",
  MainTitle: "WEST FACING  |  1820 SQFT",
  MainTitleEnd: "A2,A5",
  labels: [
    "1. LIVING & DINNING",
    "2. SIT-OUT",
    "3. MASTER BEDROOM",
    "4. TOILET",
    "5. KITCHEN",
    "6. UTILITY",
    "7. BEDROOM",
    "8. TOILET",
    "9. TOILET",
    "10. BEDROOM"
  ],
  values: [
    "29’9” X 11’0”",
    "6’6” X 11’0”",
    "14’0” X 11’0”",
    "7’1” X 7’8”",
    "9’8” X 11’0”",
    "3’11” X 11’0”",
    "12’0” X 11’0”",
    "4’11” X 7’8”",
    "4’11” X 7’8”",
    "14’0” X 11’0”"
  ]
},
{
  src: Slide3Img1,
  src2: Slide3Img2,
  alt: "Slide 3",
  alt2: "Another Slide 3",
  MainTitle: "WEST FACING  |  1615 SQFT",
  MainTitleEnd: "A3,A4",
  labels: [
    "1. LIVING",
    "2. DINNING",
    "3. SIT-OUT",
    "4. MASTER BEDROOM",
    "5. TOILET",
    "6. TOILET",
    "7. KITCHEN",
    "8. UTILITY",
    "9. BEDROOM",
    "10. BEDROOM"
  ],
  values: [
    "11’0” X 16’7”",
    "18’0” X 9’10”",
    "6’6” X 9’10”",
    "11’0” X 13’0”",
    "4’11” X 8’0”",
    "4’11” X 8’0”",
    "9’2” X 8’0”",
    "4’7” X 8’0”",
    "11’0” X 11’0”",
    "12’11” X 11’0”"
  ]
},
{
  src: Slide4Img1,
  src2: Slide4Img2,
  alt: "Slide 4",
  alt2: "Another Slide 4",
  MainTitle: "WEST FACING  |  2835 SQFT",
  MainTitleEnd: "A6",
  labels: [
    "1. LIVING",
    "2. HOME THEATER/BEDROOM",
    "3. DINING",
    "4. WASH",
    "5. TOILET",
    "6. KITCHEN",
    "7. SIT-OUT",
    "8. MASTER BEDROOM",
    "9. WALK-IN",
    "10. TOILET",
    "11. BEDROOM",
    "12. TOILET",
    "13. TOILET",
    "14. BEDROOM"
  ],
  values: [
    "24’2” X 12’0”",
    "24’2” X 12’0”",
    "10’6” X 18’2”",
    "4’11” X 4’11”",
    "8’0” X 4’11”",
    "13’0” X 13’0”",
    "41’4” X 6’6”",
    "15’3” X 12’0”",
    "6’3” X 4’11”",
    "8’4” X 4’11”",
    "13’0” X 12’0”",
    "4’11” X 8’4”",
    "4’11” X 8’4”",
    "15’3” X 12’0”"
  ]
},
{
  src: Slide5Img1,
  src2: Slide5Img2,
  alt: "Slide 5",
  alt2: "Another Slide 5",
  MainTitle: "WEST FACING  |  2850 SQFT",
  MainTitleEnd: "A7",
  labels: [
    "1. LIVING",
    "2. HOME THEATER/BEDROOM",
    "3. DINING",
    "4. WASH",
    "5. TOILET",
    "6. KITCHEN",
    "7. SIT-OUT",
    "8. MASTER BEDROOM",
    "9. WALK-IN",
    "10. TOILET",
    "11. BEDROOM",
    "12. TOILET",
    "13. TOILET",
    "14. BEDROOM"
  ],
  values: [
    "24’2” X 12’0”",
    "24’2” X 12’0”",
    "10’6” X 18’2”",
    "4’11” X 4’11”",
    "8’0” X 4’11”",
    "13’0” X 13’0”",
    "41’4” X 6’6”",
    "15’3” X 12’0”",
    "6’3” X 4’11”",
    "8’4” X 4’11”",
    "15’3” X 12’0”",
    "4’11” X 8’4”",
    "4’11” X 8’4”",
    "13’0” X 12’0”"
  ]
}
,
{
  src: Slide6Img1,
  src2: Slide6Img2,
  alt: "Slide 6",
  alt2: "Another Slide 6",
  MainTitle: "WEST FACING  |  2020 SQFT",
  MainTitleEnd: "A8,A9",
  labels: [
    "1. LIVING & DINING",
    "2. SIT-OUT",
    "3. MASTER BEDROOM",
    "4. TOILET",
    "5. KITCHEN",
    "6. UTILITY",
    "7. BEDROOM",
    "8. TOILET",
    "9. TOILET",
    "10. BEDROOM"
  ],
  values: [
    "29’6” X 12’0”",
    "6’6” X 12’0”",
    "17’6” X 13’0”",
    "5’11” X 8’0”",
    "13’0” X 8’8”",
    "13’0” X 8’8”",
    "12’4” X 12’0”",
    "4’11” X 8’4”",
    "4’11” X 8’4”",
    "12’0” X 12’0”"
  ]
}
,
{
  src: Slide7Img1,
  src2: Slide7Img2,
  alt: "Slide 7",
  alt2: "Another Slide 7",
  MainTitle: "WEST FACING  |  2335 SQFT",
  MainTitleEnd: "A10,A11",
  labels: [
    "1. LIVING & DINING",
    "2. SIT-OUT",
    "3. MASTER BEDROOM",
    "4. TOILET",
    "5. KITCHEN",
    "6. UTILITY",
    "7. BEDROOM",
    "8. TOILET",
    "9. TOILET",
    "10. BEDROOM"
  ],
  values: [
    "37’9” X 12’0”",
    "6’6” X 25’11”",
    "17’6” X 13’0”",
    "5’11” X 8’0”",
    "13’0” X 8’8”",
    "13’0” X 3’11”",
    "14’0” X 12’0”",
    "4’11” X 8’4”",
    "4’11” X 8’4”",
    "12’0” X 12’0”"
  ]
}
,
{
  src: Slide8Img1,
  src2: Slide8Img2,
  alt: "Slide 8",
  alt2: "Another Slide 8",
  MainTitle: "WEST FACING  |  2450 SQFT",
  MainTitleEnd: "A12",
  labels: [
    "1. LIVING & DINING",
    "2. SIT-OUT",
    "3. MASTER BEDROOM",
    "4. TOILET",
    "5. KITCHEN",
    "6. UTILITY",
    "7. BEDROOM",
    "8. TOILET",
    "9. TOILET",
    "10. BEDROOM",
    "11. SIT-OUT"
  ],
  values: [
    "31’3” X 12’0”",
    "6’6” X 12’0”",
    "17’6” X 13’0”",
    "5’11” X 8’0”",
    "13’0” X 8’8”",
    "13’0” X 3’11”",
    "12’0” X 11’0”",
    "4’11” X 8’4”",
    "4’11” X 8’4”",
    "12’0” X 12’0”",
    "39’0” X 6’6”"
  ]
}


];


const Section4 = () => {
  return (
    <div className={`${styles.section} section`}>
      <div className={`${styles.overlay} overlay`}>
        <Carousel
           swipeable={false} // Disable the default swipeable behavior
           showArrows={true}
           infiniteLoop={true}
           showThumbs={false}
           autoPlay={true}
           dynamicHeight={false}
           showIndicators={false}
           showStatus={true}
           className={`${styles.cuscarousal}`}
           emulateTouch={true}
        >
          {slides.map((slide, index) => (
            <div key={index} className={`${styles.carouselItem} row`}>
              <div className={styles.TextContainer + " row "}>
                <h2 className={styles.MainTitle + " col-8 text-end col-md-10 col-lg-7"}>
                  {slide.MainTitle}
                </h2>
                <h3 className={styles.MainTitleEnd + " col-4 text-end col-md-2 col-lg-3"}>
                  {slide.MainTitleEnd}
                </h3>
              </div>
              <div className={`${styles.imagesContainer} row`}>
                <div className={styles.image + " col-md-5 col-12"}>
                  <img src={slide.src} alt={slide.alt} />
                </div>
                <div className={styles.image + " col-md-7 col-12"}>
                  <img src={slide.src2} alt={slide.alt2} />
                </div>
              </div>
              <div className={`${styles.details} row`}>
                <div className="col-lg-6 col-12">
                  {slide.values.slice(0, Math.floor(slide.values.length / 2)).map((value, i) => (
                    <div className="row" key={i}>
                      <div className={styles.myLabel + " col-6 "}>
                        <p>{slide.labels[i]}</p>
                      </div>
                      <div className={styles.value + " col-6 "}>
                        <p>{value}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className={"col-lg-6 col-12"}>
                  {slide.values.slice(Math.floor(slide.values.length / 2)).map((value, i) => (
                    <div className="row" key={i}>
                      <div className={styles.myLabel + " col-6 "}>
                        <p>{slide.labels[i + Math.floor(slide.labels.length / 2)]}</p>
                      </div>
                      <div className={styles.value + " col-6 "}>
                        <p>{value}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Section4;