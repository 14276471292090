import React from 'react';

import "../../pages/Home.css";
import AllSection1 from "../../landingPagesSections/forGachibowli/AllSection1/AllSection1";
import AllSection2 from "../../landingPagesSections/forGachibowli/AllSection2/AllSection2";
import Section3 from "../../landingPagesSections/forGachibowli/Section3/Section3";
import Section4 from "../../landingPagesSections/forGachibowli/Section4/Section4";
import Section5 from "../../landingPagesSections/forGachibowli/Section5/Section5";
import Section6 from "../../landingPagesSections/forGachibowli/Section6/Section6";
import Section7 from "../../landingPagesSections/forGachibowli/Section7/Section7";
import Section8 from "../../landingPagesSections/forGachibowli/Section8/Section8";
import Section9 from "../../landingPagesSections/forGachibowli/Section9/Section9";
import Section10 from "../../landingPagesSections/forGachibowli/Section10/Section10";
import Section11 from "../../landingPagesSections/forGachibowli/Section11/Section11";
import Section12 from "../../landingPagesSections/forGachibowli/Section12/Section12";
import Section13 from "../../landingPagesSections/forGachibowli/Section13/Section13";
import Section14 from "../../landingPagesSections/forGachibowli/Section14/Section14";
import Section15 from "../../landingPagesSections/forGachibowli/Section15/Section15";
import Section16 from "../../landingPagesSections/forGachibowli/Section16/Section16";
import Section17 from "../../landingPagesSections/forGachibowli/Section17/Section17";
import Section18 from "../../landingPagesSections/forGachibowli/Section18/Section18";
import Footer from "../../components/Footer/Footer";

const banjaraHills = () => {

  return (

    <main className=" main ">
      <AllSection1 />
      <AllSection2 />
      <Section11 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 />
      <Section12 />
      <Section13 />
      {/* <Section14 /> */}
      <Section15 />
      {/* <Section16 /> */}
      <Section17 />
      <Section18 />
      <Footer />
    </main>
  );
};

export default banjaraHills;