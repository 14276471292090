import { useRef, useEffect } from "react";

// Throttle function
const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return function() {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function() {
        if ((Date.now() - lastRan) >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

const useScrollZoom = () => {
  const sectionRef = useRef(null);
  let startZoomScrollPosition = null;
  let zoomFactor = 1;

  const handleZoom = () => {
    const currentScrollY = window.scrollY;
    const section = sectionRef.current;
    const sectionTop = section.getBoundingClientRect().top;

    if (sectionTop <= 0) {
      if (startZoomScrollPosition === null) {
        startZoomScrollPosition = currentScrollY;
      }
      
      const scrollDifference = currentScrollY - startZoomScrollPosition;

      if (currentScrollY > startZoomScrollPosition) {  // Scrolling down
        zoomFactor = 1 + (scrollDifference / 3000);
        const maxZoomFactor = window.innerWidth >= 768 ? 1.5 : 1.3;
        zoomFactor = Math.min(zoomFactor, maxZoomFactor);
      } else {  // Scrolling up
        zoomFactor = Math.max(1, zoomFactor - 0.01);  
      }

      section.style.setProperty("--zoom-factor", zoomFactor);
    } else {
      startZoomScrollPosition = null;
      section.style.setProperty("--zoom-factor", 1);
    }
  };

  // Throttle the handleZoom function
  const throttledHandleZoom = throttle(handleZoom, 50);

  useEffect(() => {
    // window.addEventListener("scroll", throttledHandleZoom, { passive: true });
    // window.addEventListener("touchmove", throttledHandleZoom, { passive: true });

    return () => {
      // window.removeEventListener("scroll", throttledHandleZoom);
      // window.removeEventListener("touchmove", throttledHandleZoom);
    };
  }, []);

  return sectionRef;
};

export default useScrollZoom;
