import React from "react";
import styles from "./Section11.module.css";



import image1 from "../../../assets/images/bacgrounds/long1.jpg";
import image3 from "../../../assets/images/bacgrounds/long2.jpg";
import image2 from "../../../assets/images/bacgrounds/long3.jpg";

const Section11 = () => {

  return (
    <div className={`${styles.sectionContainer} container-fluid section`}>
      <h1 className={`${styles.title} heading`}>PROJECT DETAILS</h1>
      <div className={`${styles.desktopMode} row`}>
        <div className={`col-5 ${styles.textContainer}`}>
                  <div className={`${styles.desc}`}>
            <h2>42</h2>
            <p>Floor</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>502</h2>
            <p>Units</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>2.98 Acre</h2>
            <p>Plot Size</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>38,000 sqft</h2>
            <p>Club House</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>Upto 240 sqft</h2>
            <p>Balconies</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>3/4/5 BHK &</h2>
            <p>Duplexes</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>2/3/4/5</h2>
            <p>Bathrooms</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>100%</h2>
            <p>Vastu Compliant</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>1615 - 5700 Sq Ft</h2>
            <p>Super built-up area</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>June 2026</h2>
            <p>Possession</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>5 Level</h2>
            <p>Car Parking</p>
          </div>
          
          

        </div>
        
        <div className={` ${styles.imageContainer} col-7 `}></div>
      </div>
      <div className={`${styles.mobileMode} row`}>
        <div className={` ${styles.left} col-6`}>
          <img src={image1} alt="img" className={`${styles.image1}`}></img>
          <div className={`${styles.desc}`}>
            <h2>Upto 240 sqft</h2>
            <p>Balconies</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>3/4/5 BHK & </h2>
            <p>Duplexes</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>2/3/4/5</h2>
            <p>Bathrooms</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>100%</h2>
            <p>Vastu Compliant</p>
          </div>
     
          <img src={image2} alt="img" className={`${styles.image2}`}></img>
        </div>
        <div className={` ${styles.right} col-6`}>
          <div className={`${styles.desc}`}>
            <h2>42</h2>
            <p>Floor</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>502</h2>
            <p>Units</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>2.98 Acre</h2>
            <p>Plot Size</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>38,000 sqft</h2>
            <p>Club House</p>
          </div>

          <img src={image3} alt="img" className={`${styles.image3}`}></img>

          <div className={`${styles.desc}`}>
          <div className={`${styles.desc}`}>
            <h2>1615 - 5700 Sq Ft</h2>
            <p>Super built-up area</p>
          </div>
            <h2>June 2026</h2>
            <p>Possession</p>
          </div>
          <div className={`${styles.desc}`}>
            <h2>5 Level</h2>
            <p>Car Parking</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section11;