import React from 'react';
import styles from './terms.module.scss';

function Term() {
  return (
    <div className={styles.container}>
        <button className={styles.backButton} onClick={() => window.history.back()}>Go Back</button>

        <h1 className={styles.title}>BOOKING TERMS & CONDITIONS</h1>

        <p>The contract price shall be confirmed when the Allotment Letter is issued. <strong>QUAMBIANT</strong> reserves the right to cancel the allotment and forfeit monies paid if the amounts are not paid as per the terms of the booking.</p>

        <h2 className={styles.subtitle}>Cancellation and Withdrawal</h2>
        <p>In case the Applicant desires to withdraw the booking, within 10 days of booking, a token amount of 25% of the booking amount would be withheld as a cancellation fee. The entire booking amount would be forfeited in the event of withdrawal after the issuance of the allotment letter or after 10 days, whichever is earlier.</p>

        <h2 className={styles.subtitle}>Transfers</h2>
        <p>Transfers, without a transfer fee, are allowed only amongst family members (Father, Mother, Wife, Husband, Son, and Daughter). In case of transfer of a reservation to 3rd parties, it will be subject to <strong>QUAMBIANT's</strong> consent in writing and if consented, a transfer fee of 3% of the value of the apartment would be imposed.</p>

        <h2 className={styles.subtitle}>Refunds</h2>
        <p>All refunds, if any, will be made without interest by local cheque only, within 30 working days from the date of cancellation.</p>

        <h2 className={styles.subtitle}>Statutory Expenses</h2>
        <p>Statutory expenses like Stamp Duty & Registration charges and Legal/ Incidental expenses for registering the property and additional Stamp Duty, if demanded by the Special Dy. Commissioner Undervaluation of stamps will have to be borne by the Allottee. Service Tax as applicable. Increase in existing tax levies and any fresh Governmental levies, applicable during the contract period shall be met by the Allottee.</p>

        <h2 className={styles.subtitle}>Delivery Date</h2>
        <p>The delivery date indicated is subject to the 'Force Majeure' Clause. Every effort will be made to obtain general electrical, sanitary and water connections within the stipulated delivery date. However, no responsibility is accepted for any delay beyond the control of the Developer in obtaining the services.</p>

        <h2 className={styles.subtitle}>Specifications</h2>
        <p>Changes in standard specifications by the customer are generally not acceptable as changes adversely affect the completion schedule of the project.</p>

        <h2 className={styles.subtitle}>Booking Acceptance</h2>
        <p>This booking is subject to acceptance by <strong>QUAMBIANT</strong>, in writing and the receipt passed for earnest money is tentative.</p>

        <footer className={styles.footer}>
            Website: <a href="http://quambiantamaranthine.com" className={styles.link} target="_blank" rel="noopener noreferrer">quambiantamaranthine.com</a>
        </footer>
    </div>
  );
}

export default Term;
