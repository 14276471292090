import React, { useRef } from "react";
import styles from "./Section2.module.css";
import useScrollZoom from "../../../components/useScrollZoom";
import useColorChangeOnScroll from "../../../components/useColorChangeOnScroll";

const Section2 = () => {
  const sectionRef = useScrollZoom();
  const textRef = useRef(null);

  // Use the color change hook
  useColorChangeOnScroll(sectionRef, textRef);

  return (
    <div ref={sectionRef} className={`${styles.section} section`}>
      <div className={`${styles.overlay} overlay`}>
        <div className={`${styles.text}`}>
          <h2 ref={textRef} className={`${styles.textBody} textBody`}>
            All at once, know everything about your suite-styled living spaces with an array of specs for your cognizance.
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Section2;
