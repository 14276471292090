import React, { useState, useEffect } from "react";
import styles from "./MobileGallary.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";


import gal1 from "../../assets/galleryimages/1.webp";
import gal2 from "../../assets/galleryimages/2.webp";
import gal3 from "../../assets/galleryimages/3.webp";
import gal4 from "../../assets/galleryimages/4.webp";
import gal5 from "../../assets/galleryimages/5.webp";
import gal6 from "../../assets/galleryimages/6.webp";
import gal7 from "../../assets/galleryimages/7.webp";
import gal8 from "../../assets/galleryimages/8.webp";
import gal9 from "../../assets/galleryimages/9.webp";
import gal10 from "../../assets/galleryimages/10.webp";
import gal11 from "../../assets/galleryimages/11.webp";
import gal12 from "../../assets/galleryimages/12.webp";
import gal13 from "../../assets/galleryimages/13.webp";
import gal14 from "../../assets/galleryimages/14.webp";
import gal15 from "../../assets/galleryimages/15.webp";
import gal16 from "../../assets/galleryimages/16.webp";
import gal17 from "../../assets/galleryimages/17.webp";
import gal18 from "../../assets/galleryimages/18.webp";
import gal19 from "../../assets/galleryimages/19.webp";
import gal20 from "../../assets/galleryimages/20.webp";
import gal21 from "../../assets/galleryimages/21.webp";
import gal22 from "../../assets/galleryimages/22.webp";
import gal23 from "../../assets/galleryimages/23.webp";
import gal24 from "../../assets/galleryimages/24.webp";
import gal25 from "../../assets/galleryimages/25.webp";
import gal26 from "../../assets/galleryimages/26.webp";
import gal27 from "../../assets/galleryimages/27.webp";
import gal28 from "../../assets/galleryimages/28.webp";
import gal29 from "../../assets/galleryimages/29.webp";
import gal30 from "../../assets/galleryimages/30.webp";
import gal31 from "../../assets/galleryimages/31.webp";
import gal32 from "../../assets/galleryimages/1.webp";

const galleries = [
  [gal1, gal2],
  [gal3, gal4],
  [gal5, gal6],
  [gal7, gal8],
  [gal9, gal10],
  [gal11, gal12],
  [gal13, gal14],
  [gal15, gal16],
  [gal17, gal18],
  [gal19, gal20],
  [gal21, gal22],
  [gal23, gal24],
  [gal25, gal26],
  [gal27, gal28],
  [gal29, gal30],
  [gal31, gal32],
];


const MobileGallery = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const allImages = galleries.flat();

  useEffect(() => {
    if (selectedImageIndex !== null) {
      document.body.style.overflow = "hidden"; // Lock the scroll
    } else {
      document.body.style.overflow = "auto"; // Unlock the scroll
    }
  }, [selectedImageIndex]);

  const onImageClick = (image) => {
    const index = allImages.indexOf(image);
    setSelectedImageIndex(index);
  };

  const onCloseClick = () => {
    setSelectedImageIndex(null);
  };

  const onNextImage = () => {
    if (selectedImageIndex < allImages.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };

  const onPrevImage = () => {
    if (selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <div
        className={`${styles.sectionMobile} ${
          expanded ? styles.expanded : ""
        } section`}
      >
        {galleries.map((gallery, index) => (
          <div key={index} className={styles.rowContainer + " row "}>
            {index % 2 === 0 ? (
              <>
                <div
                  className={styles.bgImage + " col-7 "}
                  style={{ backgroundImage: `url(${gallery[0]})` }}
                  onClick={() => onImageClick(gallery[0])}
                ></div>
                <div
                  className={styles.bgImage + " col-5 "}
                  style={{ backgroundImage: `url(${gallery[1]})` }}
                  onClick={() => onImageClick(gallery[1])}
                ></div>
              </>
            ) : (
              <>
                <div
                  className={styles.bgImage + " col-5 "}
                  style={{ backgroundImage: `url(${gallery[0]})` }}
                  onClick={() => onImageClick(gallery[0])}
                ></div>
                <div
                  className={styles.bgImage + " col-7 "}
                  style={{ backgroundImage: `url(${gallery[1]})` }}
                  onClick={() => onImageClick(gallery[1])}
                ></div>
              </>
            )}
          </div>
        ))}
        <button className={styles.seeMoreButton} onClick={toggleExpand}>
          {expanded ? "See Less" : "See More"}
        </button>
      </div>

      {selectedImageIndex !== null && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <img
              src={allImages[selectedImageIndex]}
              alt="selected"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
            <div className={styles.modalClose} onClick={onCloseClick}>
              <FontAwesomeIcon
                icon={faTimes}
              />
            </div>
            <button onClick={onPrevImage} clas>&lt; Previous</button>
            <button onClick={onNextImage}>Next &gt;</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileGallery;
